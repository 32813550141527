.select {
  display: block;
  box-sizing: border-box;
  background-color: $basic-white;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 10px 15px;
  border: 1px solid $basic-black;
  outline: none;
  font-size: 16px;
  line-height: 1;
  font-family: inherit;
  color: $basic-black;
  transition: background-color 0.2s ease 0s,
  border-color 0.2s ease 0s;

  @include sm-md {
    height: 40px;
    padding: 6px 15px;
  }

  @include from-lg {
    height: 50px;
    padding: 10px 15px;
    font-size: 18px;
  }

  &_disabled,
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
}
