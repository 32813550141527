.body-opened-menu {
  overflow: hidden;
}

.nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 17px 0 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  &--opened {
    z-index: 2;
  }

  &__buttons {
    position: relative;

    @include sm-xxlg {
      align-self: flex-end;
      margin-right: 60px;
    }
  }

  &--opened &__buttons {

  }

  &__button {

    @include sm-xxlg {
      width: 95px;
      padding: 11px 5px;
      font-size: 12px;
    }

    @include lg-only {
      width: 145px;
    }

    @include xlg-only {
      width: 160px;
    }

    @include from-xxlg {
      width: 182px;
    }
  }

  &__content {
    display: none;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    box-sizing: border-box;
    padding: 55px $container-padding 30px;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    background-color: $gold;
    background-image: url("../img/bg/bg-menu.png");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    @include from-lg {
      padding: 75px $container-padding 45px;
    }
    &::after {
      @include from-xlg {
        content: "";
        width: 583px;
        height: 497px;
        background-image: url($svg-sprite + '#line-nav');
        position: absolute;
        background-size: 583px 497px;
        background-repeat: no-repeat;
        right: 0;
        top: 20%;
      }
    }
  }

  &--opened &__content {
    display: flex;
    animation: slide-down 0.2s linear 0s 1 forwards;
  }

  &__list {
    display: grid;
    align-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-grow: 1;
    overflow-y: auto;
    opacity: 0;


    @media (min-width: 1200px) {
      grid-gap: 1%;
    }

    @include from-xxlg {
      min-width: 600px;
      width: 100%;
    }
  }

  &--opened &__list {
    animation: fade-in 0.3s ease-out 0.2s 1 forwards;
  }

  &__item {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 1;
    color: rgba(255, 255, 255, 0.7);
    @include sm-only {
      font-size: 22px;
    }

    @include from-xlg {
      font-size: 48px;
    }

    &:first-child {
      white-space: nowrap;
    }

    & + & {
      @include sm-lg {
        margin-top: 3px;
      }
    }

    &--active {
      color: $basic-white;
    }

    &--only-mobile {
      @include from-lg {
        display: none;
      }
    }

    &--top {
      @include sm-lg {
        border-bottom: 1px solid $menu-border;
      }
    }

    &--bottom {
      .nav__link {
        text-transform: none;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    transition: color 0.2s ease 0s;
    padding: 4px 0 5px;

    @include from-xxlg {
      padding: 7px 10px;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 10px;
    @include sm-md {
      margin-top: 20px;
    }
    @include from-lg {
      margin-top: 30px;
    }
  }

  &__social-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    margin: 0 5px 0 0;
  }

  &__social-link {
    display: flex;
  }

  &__social-svg {
    width: 40px;
    height: 20px;
  }
}
