.balance {
  padding: 10% 0 5%;
  @include sm-md {
    padding: 100px 0 90px;
  }
  @include from-xlg {
    max-width: 1230px;
    width: 100%;
  }

  &__block {
    position: relative;
    display: flex;
  }

  &__block-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 50px auto 0;
  }

  &__img {
    max-width: 310px;
    @include sm-md {
      max-width: 174px;
    }
    @include lg-only{
      max-width: 240px;
    }
    @include xlg-only {
      max-width: 254px;
    }
  }

  &__img-b {
    margin: -50px 0 0 0;
    position: absolute;
    top: 100%;
    @include from-xxxlg {
      margin: -60px 0 0 0;
    }
  }

  &__field-title {
    font-family: $basic-title-font;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 1.1;
    text-transform: uppercase;
    background: linear-gradient(90deg, #B28B57 0%, #FEFDDD 50.52%, #B28B57 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin: 0 0 4px 0;
    @include sm-md {
      font-size: 30px;
    }
    @include lg-only {
      font-size: 26px;
    }
    @include xlg-only {
      font-size: 32px;
    }
    @include xxlg-only {
      font-size: 36px;
    }
  }

  &__text {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4;
    color: #D9D9D9;
    margin: 0;
    @include sm-lg {
      font-size: 16px;
      line-height: 1.2;
    }
  }

  &__field {
    flex-direction: row;
    grid-gap: 25px;
    align-items: flex-start;
    max-width: 355px;
    position: absolute;
    transform: translateY(1000px);
    opacity: 0;
    @include sm-md {
      max-width: 343px;
    }
    @include lg-only {
      max-width: 300px;
      padding: 15px;
    }

    &-slide {
      flex-direction: row;
      grid-gap: 15px;
      align-items: flex-start;
      position: absolute;
      max-width: 289px;

      &--heart {
        top: 90px;
        left: 2%;
      }

      &--color {
          bottom: 46px;
          left: 20%;
      }

      &--aroma {
          top: 30%;
          right: -15px;
      }

      &--taste {
          bottom: 0;
        right: -20%;
      }
    }

    &--heart {
      @include from-lg {
        top: 20px;
        left: 0;
        z-index: 2;
      }
      @include lg-only {
        top: 20px;
        left: -15%;
      }
    }

    &--color {
      @include from-lg {
        bottom: 0;
        left: 20px;
        z-index: 2;
      }
      @include lg-only {
        bottom: 10%;
        left: -19%;
      }
    }

    &--aroma {
      @include from-lg {
        top: 115px;
        right: 25px;
      }
      @include lg-only {
        top: 90px;
        right: -10%;
      }
    }

    &--taste {
      @include from-lg {
        bottom: 20px;
        right: 5%;
      }
      @include lg-only {
        bottom: 20px;
        right: -5%;
      }
    }
  }

  &__swiper {
    display: flex;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 500px;
    width: 100vw;
    overflow: hidden;

    & .swiper-wrapper {
      width: 700px;
    }
  }

  &__slide {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    position: relative;

    &-inner {
      width: 700px;
      position: relative;
    }
  }

}
