.video-for-popup {
  display: none;
}

.popup_popup-video .video-for-popup,
.popup_popup-video-second .video-for-popup{
  display: block;
  width: 100%;
  height:100%;
}

.popup_popup-video .video-content,
.popup_popup-video-second .video-content{
  @include sm-md {
    width: 300px;
    height: 200px;
  }
  @include from-lg {
    width: 560px;
    height: 315px;
  }
  @include from-xlg {
    width: 100%;
    height: 400px;
  }
}


.video-file {
  width: 100%;
  height: auto;
}
