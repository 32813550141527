.body-opened-popup {
  overflow: hidden;
}

.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  backdrop-filter: blur(100px);

  &_hidden {
    display: none;
  }

  &__wrapper {
    width: 100%;
    max-width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;

    @include sm-md {
      align-items: stretch;
    }

    @include from-lg {
      align-items: center;
    }
  }

  &__block {
    position: relative;
    box-sizing: border-box;
    max-width: 100%;
    border-radius: 20px;
    padding: 45px $container-padding;
    background-color: $basic-white;

    @include sm-md {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @include from-lg {
      margin: 15px;
      padding: 60px;
    }
  }

  &__close-btn {
    position: absolute;
    font-family: $basic-font;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    color: $gold;
    right: 14px;
    top: 17px;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    @include from-lg {
      right: 19px;
      top: 26px;
      //width: 40px;
      //height: 40px;
      //background-position: 13px 13px;
    }

    &:after {
      content: '';
      position: relative;
      display: block;
      width: 100%;
      height: 1px;
      background: $gold;
    }
  }

  &__title {
    font-family: $basic-title-font;
    color: $basic-dark;
    font-size: 60px;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;
    text-align: center;
    margin: 0;

    span {
      color: $gold;
    }
  }

  &__text {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 140%;
    color: $basic-dark;

    &.hide {
      display: none;
    }
  }

  &__text-success {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 140%;
    color: $basic-dark;
  }

  &__btns-wrap {
    margin: 20px 0 0;
    text-align: center;

    @include from-lg {
      margin: 30px 0 0;
    }
  }

  &_popup-partner {
    .popup__block {
      @include lg-only {
        max-width: 500px;
        padding: 50px 55px;
      }
      @include xlg-only {
        max-width: 636px;
        padding: 50px 110px;
      }

      @include xxlg-only {
        max-width: 636px;
        padding: 50px 110px;
      }

      @include from-xxxlg {
        max-width: 636px;
        padding: 50px 110px;
      }
    }

    .popup-btn {
      font-family: $basic-font;
      font-size: 18px;
      font-weight: 700;
      line-height: 100%;
      color: $basic-white;
      border-radius: 20px;
      background: $gold;
      padding: 24px 0;
      cursor: pointer;
    }

  }

  &-btn.about-intro-section__btn {
    padding: 0;
  }

  &_popup-video-second {
    .popup__block {
      @include from-xlg {
        max-width: 850px;
        width: 100%;
      }
    }

    .popup__wrapper {
      @include sm-md {
        align-items: center;
      }
    }
  }

  &_popup-video {
    .popup__block {
      @include from-xlg {
        max-width: 850px;
        width: 100%;
      }
    }

    .popup__wrapper {
      @include sm-md {
        align-items: center;
      }
    }
  }
}
