.footer {
  background-color: #041E42;
  background-image: url($svg-sprite + '#line');
  background-size: 790px 413px;
  background-repeat: no-repeat;
  background-position: right 50%;
  margin-top: -50px;
  padding: 10% 0 0;
  @include sm-md {
    padding: 100px 0 0;
    background-size: 340px 470px;
    background-position: right 10%;
    background-image: url($svg-sprite + '#line-m');
  }

  &__disclaimer {
    width: 100%;
    height: auto;
  }

  &__link {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0;
    display: block;
    @include sm-md {
      text-align: right;
    }
  }

  &__nav {
    padding: 0;
    list-style-type: none;
    margin: 0;
    display: grid;
    grid-gap: 30px;
    @include sm-lg {
      grid-gap: 20px;
    }
  }

  &__logo {
    display: block;
    width: 148px;
    @include sm-lg {
      width: 116px;
    }
  }

  &__logo-img {
    width: 100%;
    height: auto;
  }

  &__block {
    display: flex;
    justify-content: space-between;
    @include from-lg {
      grid-gap: 20%;
    }
  }
  &__right {
    @include from-lg {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__social {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 50px;
    @include sm-md {
      margin-top: 48px;
      align-items: flex-end;
    }
  }

  &__social-wrap {
    display: flex;
    grid-gap: 10px;
  }

  &__social-text {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 100%;
    text-align: left;
    color: #FFFFFF;
    margin-top: 0;
    margin-bottom: 30px;
    @include sm-md {
      margin-bottom: 20px;
      text-align: right;
    }
  }
}
