.survey {
  padding: 15% 0 10%;
  width: 100%;
  @include sm-md {
    padding: 100px 0 60px;
  }
  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 188px;
    line-height: 1.1;
    text-align: center;
    text-transform: uppercase;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.3) 195.41%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    @include sm-lg {
      font-size: 84px;
    }
    @include sm-only {
      font-size: 50px;
    }
    @include xlg-only {
      font-size: 110px;
    }
    @include xxlg-only {
      font-size: 130px;
    }
  }

  &__form {
    background-color: $basic-white;
    backdrop-filter: blur(2px);
    border-radius: 32px;
    padding: 35px 33px;
    @include sm-lg {
      max-width: 400px;
    }
    @include sm-only {
      padding: 20px;
    }
    @include from-xlg {
      padding: 60px 120px;
      max-width: 400px;
    }
  }

  &__name {
    font-family: $basic-title-font;
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: #383533;
    margin: 0 0 15px 0;
    @include sm-lg {
      font-size: 30px;
    }
    @include sm-only {
      font-size: 22px;
    }
    &--gold {
      color: $gold;
    }
  }

  &__btn {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4;
    padding: 10px;
  }

  &__tab {
    background: rgba(178, 149, 101, 0.3);
    border-radius: 16px;
    width: 100%;
    border: none;
    padding: 0;

    &._active {
      background-color: $gold;
    }
  }

  &__tabs {
    display: none;
    flex-direction: row;
    grid-gap: 8px;
    width: 100%;
    height: 2px;
    max-width: 154px;
    margin: 0 auto 20px;

    &._active {
      display: flex;
    }
  }

  &__btns {
    display: flex;
    grid-gap: 10px;
    justify-content: center;

    &--col {
      flex-direction: column;
      align-items: center;
      height: 80px;
      justify-content: flex-start;
    }
  }

  &__ask {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4;
    text-decoration-line: underline;
    color: $gold;
    display: block;
    text-align: center;
    @include sm-only {
      font-size: 12px;
    }
  }
}
