.question-form {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  //&__item {
  //  display: block;
  //  margin-bottom: 20px;
  //
  //  &_center {
  //    display: flex;
  //    justify-content: center;
  //    align-items: flex-start;
  //  }
  //}
  //
  //&__checkbox-wrapper {
  //  margin-bottom: 30px;
  //}
  //
  //&__submit-btn {
  //  width: 100%;
  //  margin: 12px 0 0;
  //
  //  @include from-lg {
  //    padding: 21px 10px;
  //  }
  //}
}
