.place {
  padding: 15% 0 10%;
  width: 100%;
  @include sm-md {
    padding: 100px 0 60px;
  }

  &__title {
    margin: 0 0 10px 0;
  }

  &__text {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 140%;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
    @include sm-md {
      font-size: 20px;
    }
    @include lg-only {
      font-size: 24px;
    }
    @include xlg-only {
      font-size: 36px;
    }
  }

  &__city {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #212121;
    margin: 0;
  }

  &__block-img {
    @include sm-lg {
      width: 111px;
      height: 99px;
    }
    @include from-xlg {
      width: 130px;
      height: 116px;
    }
  }

  &__img-logo {
    width: 100%;
    height: auto;
  }

  &__block-city {
    background-image: $gradient;
    padding: 8px 16px;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__address {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    color: #FFFFFF;
    margin: 0;
  }

  &__link {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-decoration-line: underline;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    grid-gap: 8px;
    margin: 0;
  }

  &__line {
    text-decoration: underline;
  }

  &__field {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    grid-gap: 30px;
    position: relative;
    padding: 15px;
    @include sm-lg {
      grid-gap: 15px;
    }
  }

  &__block-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 14px;
    @include sm-lg {
      grid-gap: 6px;
    }
  }

  &__btn {
    display: flex;
    width: fit-content;
    margin: 0 auto;
    color: $basic-black;
    font-size: 18px;
    font-weight: 700;
    line-height: 100%;
    border-radius: 20px;
    background: linear-gradient(96deg, #B28B57 -4.4%, #FEFDDD 38.92%, #DAB27D 68.85%, #FEFDDD 96.41%, #B28B57 146.82%);
    padding: 24px 40px;
  }

  &__blur::after,
  &__blur::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    backdrop-filter: blur(22px);
  }

  &__blur::before {
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  &__blur::after {
    z-index: 2;
  }

  &__slide {
    overflow: hidden;
    border-radius: 20px;
    max-width: 423px;
    @include sm-lg {
      max-width: 379px;
    }
  }

  &__swiper {
    overflow: visible;
  }

  &__swiper--top {
    margin-bottom: 16px;
    margin-top: 50px;
  }

  &__swiper--top &__slide:first-child {
    margin-left: 20%;
  }

  &__swiper--bottom {
    margin-bottom: 30px;
  }
}
