.blocked {
  position: relative;
  width: 100%;
  height: 100%;

  &::before {
    content: "";
    display: block;
    background-color: rgba(255, 255, 255, 0.4);
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::after {
    content: "";
    display: block;
    background-color: transparent;
    background-image: url("../img/icons/loader.svg");
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
  }
}