.recommendation {
  padding: 15px;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  border: 2px solid #FEFDDD;
  @include from-lg {
    padding: 20px;
    display: grid;
    grid-template-columns: 124px 1fr;
    grid-gap: 40px;
    max-width: 492px;
  }

  &__name {
    font-family: $basic-title-font;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 1.2;
    background: $gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-transform: uppercase;
    margin: 0;
    @include sm-only {
      font-size: 24px;
    }

    &--top {
      margin-top: 20px;
    }
  }

  &__text {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    color: #FFFFFF;
    margin: 0;
    @include sm-only {
      font-size: 18px;
    }
  }

  &__title {
    font-family: $basic-title-font;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 1.2;
    text-transform: uppercase;
    margin: 0;
    @include sm-md {
      margin-bottom: 14px;
    }
    @include sm-only {
      font-size: 24px;
    }
  }
}
