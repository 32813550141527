.up {
  background-color: $basic-black;
  position: fixed;
  bottom: 18%;
  right: 40px;
  z-index: 10;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 1px solid $basic-white;
  display: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &--show {
    display: flex;
  }
}