.burger-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 7;
  width: 101px;
  height: auto;
  border: 0 none;
  margin: 0;
  padding: 0;
  outline: none;
  background: transparent;
  cursor: pointer;

  &__svg {
    width: 100%;
  }

  &__line {
    position: relative;
    display: block;
    width: 32px;
    height: 2px;
    background-color: #4AA149;
    flex-shrink: 0;
    transform-origin: 1px;
    transition: transform 0.2s linear 0s,
    opacity 0.1s linear 0s,
    background-color 0.2s ease 0s;
  }

  &__close {
    display: none;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    text-align: center;
    color: #FFFFFF;
    @include sm-only {
      font-size: 16px;
    }
  }

  &--opened {
    .burger-btn__close {
      display: block;
    }
    .burger-btn__svg {
      display: none;
    }
  }

  &--opened &__line {
    background-color: $basic-black;

    &:first-child {
      transform: rotate(45deg);
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
    }
  }
}

.page--light {
  .burger-btn {
    &__line {
      background-color: $basic-black;
    }
  }
}
