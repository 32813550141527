@import 'node_modules/normalize-scss/sass/normalize/_import-now';
@import 'node_modules/swiper/swiper.scss';
@import 'node_modules/swiper/components/a11y/a11y.scss';
@import 'node_modules/swiper/components/navigation/navigation.scss';
@import './assets/variables';
@import './assets/mixins';
@import './assets/keyframes';
@import './assets/_fonts';

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: $basic-font;
  color: $black-text-color;
  background-color: $basic-bg-color;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

.body__block-logo {
    display: none;
  position: absolute;
  top: 0;
  padding: 28px 20px;
  width: calc(100% - 40px);
  z-index: 22;
  @include sm-md {
    padding: 20px;
  }

  & .container {
    display: flex;
    padding: 0;
    margin: 0;
  }
}

.attention-body .body__block-logo {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

.container {
  padding: 0 20px;
  width: calc(100% - 40px);
  max-width: $container-width;
  margin: 0 auto;
}

.page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  scroll-behavior: smooth;
  position: relative;
  z-index: 1;
  padding-top: 60px;

  @include sm-md {
    max-width: 375px;
  }

  @include lg-only {
    max-width: 768px;
  }

  @include xlg-only {
    max-width: 1001px;
  }

  @include xxlg-only {
    max-width: 1361px;
  }

  @include from-xxxlg {
    max-width: 1920px;
  }
}


.page-main {
  min-width: 0;
  position: relative;

  & > * {
    min-width: 0;
  }
}

.title {
  font-family: 'Stapel', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  @include sm-lg {
    font-size: 45px;
  }
  @include sm-only {
    font-size: 27px;
  }
  @include from-xlg {
    font-size: 78px;
  }
  @include from-xxlg {
    font-size: 88px;
  }

  &--gradient {
    background: linear-gradient(90deg, #B28B57 0%, #FEFDDD 50.52%, #B28B57 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}

.desktop {
  @include sm-md {
    display: none!important;
  }
}

.mobile {
  @include from-lg {
    display: none!important;
  }
}

.no-mobile {
  @media (max-width: 456px) {
    display: none;
  }
}

@import 'UI/visually-hidden';
@import 'UI/overflow-hidden';
@import 'UI/btn';
@import 'UI/close-btn';
@import 'UI/burger-btn';
@import 'UI/input-text';
@import 'UI/input-label';
@import 'UI/textarea';
@import 'UI/checkbox';
@import 'UI/select';
@import 'UI/form-loading';
@import 'UI/form-error';
@import 'UI/popup';
@import 'UI/button-icon';
@import 'UI/copyright';
@import 'UI/wrapper';
@import 'UI/input-number';
@import 'UI/btn-close';
@import 'UI/error';
@import 'UI/up';
@import 'UI/empty';
@import 'UI/blocked';
@import 'UI/agreement';
@import 'UI/video-for-popup';

@import 'components/common/header';
@import 'components/common/nav';
@import 'components/common/footer';
@import 'components/common/question-form';

@import 'components/section/screen';

@import 'components/block/field';
@import 'components/block/exclusive';
@import 'components/block/enjoy';
@import 'components/block/balance';
@import 'components/block/industry';
@import 'components/block/info';
@import 'components/block/recommendation';
@import 'components/block/combination';
@import 'components/block/place';
@import 'components/block/survey';
@import 'components/block/star';
@import 'components/block/form';
@import 'components/block/attention';
@import 'components/block/animation';
@import 'components/block/cookies';
@import 'components/block/logo';

