.btn-close {
  background-color: transparent;
  width: 15px;
  height: 15px;
  position: relative;

  &::before,
  &::after {
    content: "";
    display: block;
    width: 15px;
    height: 1px;
    background-color: $basic-white;
    position: absolute;
    top: 50%;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}
