.animation {
  &-heart {
    @include from-lg {
      animation: fly-left 0.5s forwards;
    }
  }

  &-color {
    @include from-lg {
      animation: fly-left 1s forwards;
    }
  }

  &-aroma {
    @include from-lg {
      animation: fly 0.2s forwards;
    }
  }

  &-taste {
    @include from-lg {
      animation: fly 1.3s forwards;
    }
  }
  &-industry {
    animation-name: opacityIn;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        animation-delay: $i * 0.2s;
      }
    }
  }
}

@keyframes fly-left {
  0% {
    transform: translate(-1000px, 500px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes fly {
  0% {
    transform: translate(1000px, 500px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes opacityIn {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
  }
}


@keyframes right-left {
  0% {
    transform: translateX(-100%);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 1;
  }
}

