.checkbox-label {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
  line-height: 1.3;

  @include from-lg {
    font-size: 16px;
    line-height: 1.25;
  }

  &__hidden-checkbox {

  }

  &__text {
    position: relative;
    display: inline-block;
    vertical-align: top;
    user-select: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    color: $basic-dark;
  }

  &__link {
    color: inherit;
    font-style: normal;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
      text-decoration: underline;
    }
  }
}

.question-form__checkbox-wrapper input,
.spec__checkbox-wrapper input{
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  clip: rect(0 0 0 0);
}

.checkbox-label__hidden-checkbox + label {
  position: relative;
  padding-left: 30px;
  top: 0;
  left: 2px;
  cursor: pointer;
}

.checkbox-label__hidden-checkbox + label::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border: none;
  background-color: $basic-white;
  border: 1px solid $basic-gray-border;
  border-radius: 3px;
}

.checkbox-label__hidden-checkbox:checked + label::after {
  opacity: 1;
}

.checkbox-label__hidden-checkbox + label::after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 8px;
  height: 5px;
  border-left: 2px solid $gold;
  border-bottom: 2px solid $gold;
  transform: rotate(-45deg);
  opacity: 0;
  transition: all 0.2s;
}
