.exclusive {
  max-width: 1146px;
  padding: 7% 0 0;
  @include sm-md {
    max-width: 375px;
    padding-top: 130px;
  }
  @include from-lg {
    margin-left: auto;
    display: grid;
    grid-template-columns: 1fr 30%;
    align-items: flex-end;
    margin-right: auto;
  }
  @include lg-only {
    margin-left: 70px;
    margin-right: 40px;
    padding: 10% 0 0;
  }
  @include from-xxlg {
    margin-right: 70px;
    grid-template-columns: 1fr 294px;
  }
  @include xxlg-only {
    margin-left: 0;
  }
  @include from-xxlg {
    width: 100%;
  }
  &__left {
    display: grid;
    grid-template-columns: 55% 1fr;
    @include xxlg-only {
      grid-template-columns: 60% 1fr;
    }
  }

  &__right {
    margin-bottom: 40px;
  }

  &__block-img {
    display: flex;
    justify-content: center;
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 432px;
    @include sm-md {
      max-width: 212px;
    }
    @include lg-only {
      max-width: 302px;
    }
    @include xlg-only {
      max-width: 352px;
    }
    @include xxlg-only {
      max-width: 392px;
    }
  }

  &__logo,
  &__text-logo {
    width: 100%;
    height: auto;
  }

  &__logo {
    max-width: 154px;
    @include from-lg {
      max-width: 200px;
    }
    @include from-xxlg {
      max-width: 298px;
    }
  }

  &__glas {
    width: 100%;
    max-width: 331px;
    height: auto;
    @include sm-md {
      max-width: 192px;
    }
    @include lg-only {
      max-width: 210px;
    }
    @include xlg-only {
      max-width: 250px;
    }
  }

  &__text {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    margin: 0 0 10px 0;
    text-align: center;
    @include from-lg {
      font-family: 'Stapel', sans-serif;
      font-weight: 400;
      font-size: 24px;
      text-transform: uppercase;
      color: #F6DEBC;
    }
  }

  &__field {
    @include from-lg {
      background-color: transparent;
    }
    @include lg-only {
      padding: 0 20px 30px;
    }
    @include from-xlg {
      padding: 0 0 80px 60px;
    }
  }
}
