.form-error {
  margin: 0 0 20px;
  font-size: 16px;
  line-height: 1.2;
  color: $basic-red;

  @include from-lg {
    margin: 0 0 25px;
    font-size: 18px;
  }
}
