.cookies {
  position: fixed;
  z-index: 21;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 20px 0;
  width: calc(100% - 100px);
  padding: 30px;
  background-color: rgba(0,0,0,0.7);
  display: none;
  align-items: center;
  justify-content: center;
  grid-gap: 30px;
  backdrop-filter: blur(6px);
  border: 1px solid $gold;
  border-radius: 20px;
  max-width: 1300px;
  @include sm-md {
    margin: 15px 0;
    padding: 15px;
    width: calc(100% - 60px);
    grid-gap: 15px;
    flex-direction: column;
    align-items: flex-start;
  }

  &__btn {
    max-width: 200px;
  }

  &._active {
    display: flex;
  }

  &__text {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    margin: 0;
    @include sm-md {
      font-size: 16px;
    }
  }
}
