.screen {
  position: relative;
  background-size: cover;
  background-color: rgba(0,0,0,0.5);
  background-position: center bottom;
  background-repeat: no-repeat;
  display: block;
  width: 100vw;
  overflow-x: hidden;
  -webkit-mask: url("../img/bg/mask.svg");
  mask: url("../img/bg/mask.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center bottom;
  mask-position: center bottom;

  &::before {
    background-image: url("../img/line.svg");
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    background-repeat: no-repeat;
    background-position: center bottom;
    pointer-events: none;
  }

  &-one {
    background-image: url("../img/bg/bg1.png");
    z-index: 10;
    @include sm-md {
      background-image: url("../img/bg/bg1-min.png");
    }
  }

  &-two {
    background-image: url("../img/bg/bg2.png");
    margin-top: -50px;
    z-index: 9;
    max-height: none!important;
    overflow: hidden;
  }

  &-three {
    background-image: url("../img/bg/bg3.png");
    margin-top: -50px;
    z-index: 8;
    min-height: 600px;
    @include from-xxlg {
      min-height: 745px;
    }
  }

  &-four {
    background-image: url("../img/bg/bg4.png");
    margin-top: -50px;
    z-index: 7;
  }

  &-five {
    background-image: url("../img/bg/bg5.png");
    margin-top: -50px;
    z-index: 6;
  }
  &-six {
    background-image: url("../img/bg/bg6.png");
    margin-top: -50px;
    z-index: 5;
  }
  &-seven {
    background-image: url("../img/bg/bg7.png");
    margin-top: -50px;
    z-index: 4;
  }

  &-five {
    background-image: url("../img/bg/bg5.png");
    margin-top: -50px;
    z-index: 6;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
