.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border: 1px solid transparent;
  border-radius: 20px;
  line-height: 100%;
  text-align: center;
  padding: 15px;
  width: 100%;
  color: $basic-dark;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;

  &--gradient {
    background: $gradient;
    border: none;
  }

  &--gold-bg {
    border: 1px solid $gold;
    background-color: $gold;
    color: #FFFFFF;
  }
  &--gold {
    border: 1px solid $gold;
    color: $gold;
  }
}
