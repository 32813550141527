//colors
$basic-black: #000000;
$basic-dark: #383533;
$basic-green: #1B8B3D;
$basic-green2: #4AA149;
$basic-gray-border: #E1E1E1;
$gray-bg: rgba(201, 201, 201, 0.8);
$green-light: #E6F1E1;
$green-dark: #0C421D;
$red-dark: #BF2756;

$menu-border: #DFDFDF;
$basic-white: #FFFFFF;
$basic-red: #D80027;
$basic-gray: #777777;
$basic-gray-lk: #888888;
$basic-mid-gray: #555555;
$basic-dark-gray: #444444;
$basic-light-gray: #F7F7F7;
$basic-c-gray: #C4C4C4;
$basic-e-gray: #E5E5E5;
$hover-social: #A7A7A7;
$error: #D80027;

$gradient: linear-gradient(95.9deg, #B28B57 -4.4%, #FEFDDD 38.92%, #DAB27D 68.85%, #FEFDDD 96.41%, #B28B57 146.82%);
$gradient-round: linear-gradient(131.07deg, #B28B57 6.76%, #FEFDDD 51.17%, #B28B57 94.66%);
$gold: #B29565;

////text
$black-text-color: $basic-white;

///input-color
$input-bg-white: $basic-white;
$input-text-gray: #8C8A89;
$input-border: #D8D8D8;

//fonts
$basic-font: 'Akrobat', sans-serif;
$basic-title-font: 'Stapel', sans-serif;

//images
$svg-sprite: '../img/svg-sprite/sprite.view.svg';
$basic-overlay-color: rgba(0, 0, 0, 0.25);

//bg
$basic-bg-color: $basic-white;


//sizes
$container-width: 1440px;

//$narrow-container-width: 830px;
$container-padding: 20px;
$popup-padding: 40px;

$screen-sm-min: 375px;
$screen-md-min: 767px;
$screen-lg-min: 1000px;
$screen-xlg-min: 1360px;
$screen-xxlg-min: 1920px;

$screen-h-sm-min: 600px;
$screen-h-md-min: 800px;
$screen-h-lg-min: 1000px;


///properties

