.textarea {
  position: relative;
  box-sizing: border-box;
  background-color: $input-bg-white;
  width: 100%;
  max-width: 100%;
  height: 100px;
  margin: 0;
  padding: 10px 15px;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: 16px;
  line-height: 1;
  color: $basic-black;
  resize: none;
  border-radius: 20px;
  transition: background-color 0.2s ease 0s,
  border-color 0.2s ease 0s;

  @include from-lg {
    height: 185px;
    padding: 19px 22px;
    font-size: 18px;
  }

  &::placeholder {
    color: $basic-gray;

  }

  &_disabled,
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
}
