.close-btn {
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  transition: opacity 0.2s ease 0s;

  @include sm-md {
    width: 26px;
    height: 26px;
  }

  @include from-lg {
    width: 40px;
    height: 40px;
  }

  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    display: block;
    height: 1px;
    background-color: $basic-black;

    @include sm-md {
      left: 6px;
      width: 16px;
    }

    @include from-lg {
      left: 9px;
      width: 22px;
    }
  }

  &::after {
    transform: rotate(45deg);
  }

  &::before {
    transform: rotate(-45deg);
  }

  &:hover {
    opacity: 0.5;
  }
}
