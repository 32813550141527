.attention-body {
  position: relative;
  overflow: hidden;
  &::before,
  &::after {
    content: "";
    z-index: 20;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &::before {
    background-color: rgba(0, 0, 0, 0.5);
  }
  &::after {
    backdrop-filter: blur(22px);
  }


}

.attention {
  display: none;
  position: fixed;
  z-index: 22;
  top: 50%;
  left: 50%;
  background-color: $basic-white;
  padding: 60px 100px;
  border-radius: 32px;
  transform: translate(-50%, -50%);
  @include sm-lg {
    padding: 30px 20px;
    width: 80%;
    max-width: 400px;
  }
  @include sm-md {
    top: 35%;
  }

  &__ask {
    font-family: $basic-title-font;
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: #383533;
    margin: 0;
    @include sm-lg {
      font-size: 22px;
    }
  }

  &__btns {
    display: flex;
    grid-gap: 10px;
    margin-top: 20px;
    justify-content: center;
  }

  &._active {
    display: block;
  }
}

.attention-no {
  display: none;
  position: fixed;
  z-index: 21;
  top: 50%;
  left: 50%;
  border-radius: 32px;
  transform: translate(-50%, -50%);
  overflow: hidden;
  @include sm-md {
    width: 96%;
    max-width: 450px;
  }

  &._active {
    display: block;
  }

  &__bg {
    width: 100%;
    height: 100%;
    background-color: $basic-white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;
    @include sm-md {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    &::after {
      content: "";
      background-image: url("../img/bg/bg-no-face.png");
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      position: absolute;
      right: -7px;
      bottom: -3px;
      width: 165px;
      height: 177px;
      @include sm-only {
        width: 100px;
        height: 108px;
      }
      @include md-only {
          width: 130px;
          height: 134px;
      }
      @include lg-only {
        width: 140px;
        height: 144px;
      }
    }
  }

  &__ask {
    margin: 0;
    padding: 40px 0;
  }
}
