.input-label {
  font-size: 16px;
  line-height: 1.1;
  color: $basic-white;

  @include from-lg {
    font-size: 18px;
  }

  &__text {
    display: inline-block;
    vertical-align: top;
    margin: 0 0 10px;

    @include from-lg {
      margin: 0 0 15px;
    }
  }
}
