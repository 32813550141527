.agreement {
  display: none;
  margin: 12px 0;
  padding: 5px;
  overflow-y: auto;
  max-height: 100px;
  user-select: none;
  background-color: $basic-e-gray;

  @include from-lg {
    margin: 25px 0;
    padding: 15px;
  }

  &_open {
    display: block;
  }

  p {
    font-size: 12px;
    line-height: 1.2;
    margin: 0 0 5px;
  }
}
