@keyframes rotate-plus {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-minus {
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes flicker {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes move {
  0% {
    transform: translate(calc(0 / var(--c1)), calc(0 / var(--c1)));
  }

  25% {
    transform: translate(calc(150px / var(--c1)), calc(625px / var(--c1)));
  }

  50% {
    transform: translate(calc(200px / var(--c1)), calc(225px / var(--c1)));
  }

  75% {
    transform: translate(calc(410px / var(--c1)), calc(145px / var(--c1)));
  }

  100% {
    transform: translate(calc(0 / var(--c1)), calc(0 / var(--c1)));
  }
}

@keyframes ball-zoom {
  0%, 100% {
    transform: scale(var(--s1));
  }

  50% {
    transform: scale(var(--s2));
  }
}

@keyframes ball-one {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  25% {
    opacity: 1;
    transform: translate(20%, 60%);
  }

  50% {
    opacity: 1;
    transform: translate(40%, 120%);
  }

  75% {
    opacity: 1;
    transform: translate(60%, 180%);
  }
  100% {
    opacity: 0;
    transform: translate(80%, 240%);
  }
}

@keyframes ball-two {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  25% {
    opacity: 1;
    transform: translate(1%, 35%);
  }

  50% {
    opacity: 1;
    transform: translate(2%, 70%);
  }

  75% {
    opacity: 1;
    transform: translate(3%, 105%);
  }
  100% {
    opacity: 0;
    transform: translate(4%, 140%);
  }
}

@keyframes ball-three {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  25% {
    opacity: 1;
    transform: translate(-20%, 65%);
  }

  50% {
    opacity: 1;
    transform: translate(-40%, 130%);
  }

  75% {
    opacity: 1;
    transform: translate(-60%, 195%);
  }
  100% {
    opacity: 0;
    transform: translate(-80%, 260%)
  }
}

@keyframes cyto-one {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  25% {
    opacity: 1;
    transform: translate(1%, 25%);
  }

  50% {
    opacity: 1;
    transform: translate(2%, 50%);
  }

  75% {
    opacity: 1;
    transform: translate(3%, 75%);
  }
  100% {
    opacity: 1;
    transform: translate(4%, 100%);
  }
}

@keyframes cyto-two {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  25% {
    opacity: 1;
    transform: translate(50%, 75%);
  }

  50% {
    opacity: 1;
    transform: translate(100%, 150%);
  }

  75% {
    opacity: 1;
    transform: translate(150%, 225%);
  }
  100% {
    opacity: 1;
    transform: translate(200%, 300%);
  }
}

@keyframes cyto-three {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  25% {
    opacity: 1;
    transform: translate(-10%, 45%);
  }

  50% {
    opacity: 1;
    transform: translate(-20%, 90%);
  }

  75% {
    opacity: 1;
    transform: translate(-30%, 135%);
  }
  100% {
    opacity: 1;
    transform: translate(-40%, 180%);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}