.enjoy {
  max-width: 1194px;
  top: 0;
  padding: 10% 5% 5%;
  position: absolute;
  @include sm-md {
    padding: 100px 20px 40px;
    width: calc(100% - 40px);
  }

  &__text {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 140%;
    color: #FFFFFF;
    margin: 0;
    @include sm-md {
      font-size: 20px;
    }
    @include sm-only {
      font-size: 16px;
    }
    @include lg-only {
      font-size: 28px;
    }
    @include xlg-only {
      font-size: 34px;
    }
    @include xxlg-only {
      font-size: 38px;
    }
  }

  &__field {
    max-width: 1076px;
    flex-direction: row;
    align-items: flex-start;
    grid-gap: 40px;
    @include sm-md {
      grid-gap: 15px;
    }
  }

  &__title {
    @include sm-md {
      margin: 0 0 20px;
    }
    margin-bottom: 44px;
  }
}
