.form {
  margin: 0 auto;
  &__step {
    display: none;
    min-height: 170px;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 20px;
    @include from-lg {
      min-width: 389px;
    }

    &._active {
      display: flex;
    }
  }
}
