.combination {
  padding: 15% 0 10%;
  width: 100%;
  @include sm-md {
    padding: 100px 0 60px;
  }
  @include xlg-only {
    max-width: 1010px;
  }
  @include xxlg-only {
    max-width: 1200px;
  }

  &__btn {
    border: 1.5px solid rgba(217, 217, 217, 0.4);
    border-radius: 50%;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    display: flex;

    @include sm-lg {
      width: 66px;
      height: 66px;
    }
    @include sm-only {
      width: 44px;
      height: 44px;
    }

    @include xlg-only {
      width: 80px;
      height: 80px;
    }

    @include from-xxlg {
      width: 120px;
      height: 120px;
    }

    @include from-xxxlg {
      width: 137px;
      height: 137px;
    }

    &::after {
      content: "";
      background-image: url($svg-sprite + '#arrow');
      background-repeat: no-repeat;
      @include sm-lg {
        width: 19px;
        height: 19px;
        background-size: 17px 19px;
      }
      @include sm-only {
        width: 14px;
        height: 14px;
        background-size: 12px 14px;
      }
      @include xlg-only {
        width: 22px;
        height: 22px;
        background-size: 20px 22px;
      }

      @include from-xxlg {
        width: 40px;
        height: 40px;
        background-size: 38px 40px;
      }

      @include from-xxxlg {
        width: 50px;
        height: 50px;
        background-size: 48px 50px;
      }
    }
  }

  &__prev {
    &::after {
      transform: rotate(180deg);
    }
  }
  &__prev {
    @include sm-lg {
      top: 22px;
    }
  }

  &__beer {
    @include sm-lg {
      display: none;
    }
    @include from-xlg {
      position: absolute;
      top: 0;
      left: 8%;
      width: 200px;
      height: 200px;
    }
    @include from-xxlg {
      top: 45px;
      left: 12%;
    }
    @include from-xxxlg {
      width: 278px;
      height: 278px;
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    grid-gap: 8px;
    @include from-xlg {
      grid-gap: 40px;
      top: 40%;
      left: auto;
      right: 12%;
    }
    @include from-xxlg {
      right: 14%;
      top: 40%;
    }
  }

  &__swiper--img {
    position: relative;
    overflow: visible;
    @include sm-lg {
      margin-left: 80px;
    }
    @include sm-only {
      margin-left: 50px;
    }
    @include sm-only {
      max-width: 240px;
    }
    @include md-only {
      max-width: 300px;
    }
    @include lg-only {
      max-width: 350px;
    }
    @include from-xlg {
      position: absolute;
      right: 0;
      top: 0;
      overflow: visible;
      border: 1.5px solid rgba(217, 217, 217, 0.4);
      border-radius: 50%;
      margin-right: -50px;
      width: 500px;
      height: 500px;
    }
    @include from-xxlg {
      width: 600px;
      height: 600px;
    }
    @include from-xxxlg {
      width: 700px;
      height: 700px;
    }

    & .swiper-wrapper.next-rotate {
      transform: rotate(280deg);
    }

    &.swiper-wrapper {
      @include from-xlg {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  &__swiper {
    transition: transform 0.5s ease-in-out;
    @include sm-lg {
      margin-bottom: 20px;
    }

    &--info {
      @include from-xlg {
        width: 400px;
        margin-left: 0;
        margin-top: -300px;
        margin-bottom: 30px;
      }
      @include from-xxxlg {
        margin-top: -280px;
      }
    }
  }

  &__title-head {
    position: relative;
    z-index: 2;
  }

  &__block {
    position: relative;
    @include sm-lg {
      margin-right: -20px;
      max-height: 300px;
    }
    @include from-xlg {
      height: 650px;
    }
    @include from-xxlg {
      height: 750px;
    }
    @include from-xxxlg {
      height: 850px;
    }
  }

  &__swiper--img &__slide {
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;


    & {
      .combination__img {
        @include from-xlg {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }

  &__swiper--img &__slide--fish {
    padding-right: 0;
    width: 100%;
  }

  &__info-slide {
    opacity: 0!important;
  }

  &__info-slide.swiper-slide-thumb-active {
    opacity: 1!important;
  }

  &__title {
    font-family: $basic-title-font;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 1.1;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 0 0 4px 0;
    @include sm-lg {
      font-size: 30px;
    }
  }

  &__text {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.2;
    color: #FFFFFF;
    margin: 0;
    @include sm-only {
      font-size: 16px;
    }
  }

  &__block-img {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    @include from-xlg {
      display: block;
      width: 500px;
      height: 500px;
    }
    @include from-xxlg {
      width: 700px;
      height: 700px;
    }
    @include from-xxxlg {
      width: 700px;
      height: 700px;
    }
  }

  &__img-meat {
    transition: tranform 0.5s ease-in-out;
    position: absolute;
    @include xlg-only {
      transform: translate3d(-60%, 22%, 0) scale(0.7);
    }

    @include from-xxlg {
      transform: translate3d(-45%, 15%, 0) scale(0.7);
    }
    @include from-xxxlg {
      transform: translate3d(-60%, 15%, 0) scale(0.7);
    }

    &._active {
      @include from-xlg {
        transform: translate3d(-60%, 22%, 0) scale(1);
      }

      @include from-xxlg {
        transform: translate3d(-45%, 15%, 0) scale(1);
      }
      @include from-xxxlg {
        transform: translate3d(-60%, 15%, 0) scale(1);
      }
    }
  }

  &__img-salad {
    transition: tranform 0.5s ease-in-out;
    position: absolute;
    @include from-xlg {
      transform: translate3d(60%, -45%, 0) scale(0.7);
    }
    @include from-xxlg {
      transform: translate3d(80%, -45%, 0) scale(0.7);
    }
    @include from-xxxlg {
      transform: translate3d(50%, -50%, 0) scale(0.7);
    }

    &._active {
      @include from-xlg {
        transform: translate3d(60%, -45%, 0) scale(1);
      }

      @include from-xxlg {
        transform: translate3d(80%, -45%, 0) scale(1);
      }

      @include from-xxxlg {
        transform: translate3d(50%, -50%, 0) scale(1);
      }
    }
  }

  &__img-fish {
    transition: tranform 0.5s ease-in-out;
    position: absolute;
    @include from-xlg {
      transform: translate3d(60%, 110%, 0) scale(0.7);
    }
    @include from-xxlg {
      transform: translate3d(55%, 90%, 0) scale(0.7);
    }
    @include from-xxxlg {
      transform: translate3d(40%, 90%, 0) scale(0.7);
    }

    &._active {
      @include from-xlg {
        transform: translate3d(60%, 110%, 0) scale(1) rotate(-120deg);
      }
      @include from-xxlg {
        transform: translate3d(55%, 90%, 0) scale(1) rotate(-120deg);
      }
      @include from-xxxlg {
        transform: translate3d(40%, 90%, 0) scale(1) rotate(-120deg);
      }
    }
  }

  &__img {
    @include from-xlg {
      width: 350px;
    }
    @include from-xxlg {
      width: 450px;
    }
    @include from-xxxlg {
      width: 550px;
    }

    &-fish {
      @include from-xlg {
        width: 380px;
      }
      @include from-xxlg {
        width: 530px;
      }
      @include from-xxxlg {
        width: 580px;
      }
    }
  }
}
