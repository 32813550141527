.star {
  &__svg {
    fill-opacity: 0.15;
    fill: #202020;
  }

  &__label._active .star__svg {
    fill-opacity: 1;
    opacity: 1;
    fill: $gold;
  }

  &__text {
    color: $basic-black;
    margin: 0 auto 10px auto;
    display: none;
  }

  &__text._active {
    display: block;
  }
}

.survey__btns:hover {
  .star__text {
    display: block;
  }
}
