.info {
  padding: 15% 0 25px;
  width: 100%;
  @include sm-md {
    padding: 100px 0 0;
  }
  @include from-lg {
    display: grid;
    grid-template-columns: 1fr 30%;
    grid-gap: 10%;
    align-items: flex-end;
  }

  &__stand {
    padding: 20px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    border-radius: 32px;
    display: grid;
    grid-gap: 15px;
    width: 100%;
    border: 2px solid #FEFDDD;
    @include sm-lg {
      grid-gap: 10px;
      margin-bottom: 30px;
    }
    @include from-xlg {
      margin-bottom: 50px;
    }
    @include xlg-only {
      max-width: 500px;
    }
    @include xxlg-only {
      max-width: 560px;
    }
    @include from-xxlg {
      margin-bottom: 100px;
      max-width: 619px;
    }
  }

  &__col {
    min-width: 77px;
    @include sm-only {
      min-width: 60px;
    }
    &--size {
      @include from-xlg {
        min-width: 75px;
      }
      @include from-xxlg {
        min-width: 100px;
      }
      @include from-xxxlg {
        min-width: 124px;
      }
    }
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    grid-gap: 20px;
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.4;
    color: #FFFFFF;
    margin: 0;
    @include sm-lg {
      font-size: 20px;
    }
    @include sm-only {
      font-size: 16px;
    }
    @include xlg-only {
      font-size: 22px;
    }
  }

  &__text {
    margin: 0;
    font-family: $basic-title-font;
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 1.1;
    text-transform: uppercase;
    color: #FFFFFF;
    @include sm-lg {
      font-size: 25px;
    }
    @include sm-only {
      font-size: 20px;
    }
    @include xlg-only {
      font-size: 30px;
    }
    @include xxlg-only {
      font-size: 40px;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__logo {
    margin-bottom: 30px;
    max-width: 300px;
    @include sm-md {
      max-width: 280px;
    }
    @include sm-only {
      width: 220px;
    }
    @include lg-only {
      max-width: 290px;
    }
    @include xlg-only {
      max-width: 300px;
    }
  }

  &__block-img {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include sm-md {
      align-items: center;
    }
  }

  &__glas {
    width: 100%;
    @include sm-md {
      max-width: 133px;
    }
    @include from-lg {
      max-width: 230px;
    }
    @include from-xlg {
      max-width: 250px;
    }
    @include from-xxlg {
      max-width: 280px;
    }
    @include from-xxxlg {
      max-width: 367px;
    }
  }
}
