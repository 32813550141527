.button-icon {
  padding: 5px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;

  &__svg {
    fill: $basic-white;
  }

  &__search {
    fill: transparent;
    stroke: $basic-white;
  }

  &--tablet {
    @include sm-md {
      display: none;
    }
  }
}

.page--light {
  .button-icon {
    &__svg {
      fill: $basic-black;
    }

    &__search {
      fill: transparent;
      stroke: $basic-black;
    }
  }
}

.page--buy {
  max-width: none;
}
