.field {
  background-color: rgba(0,0,0,0.7);
  backdrop-filter: blur(6px);
  border-radius: 20px;
  padding: 15px 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include lg-only {
    padding: 20px 27px;
  }
  @include from-xlg {
    padding: 28px 34px;
  }

  &__cube {
    @include sm-md {
      width: 80px;
      height: 70px;
    }
    @include lg-only {
      width: 80px;
      height: 70px;
    }
    @include xlg-only {
      width: 110px;
      height: 97px;
    }
    @include from-xxlg {
      width: 130px;
      height: 116px;
    }
  }

  &__round {
    width: 100px;
    height: 100px;
    background-image: $gradient-round;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    @include sm-lg {
      width: 50px;
      height: 50px;
    }
    @include sm-only {
      width: 30px;
      height: 30px;
    }
  }
}
