.error {
  border-color: $error;
}

.error-text {
  color: $error;
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  position: absolute;
  right: 0;
  bottom: -25px;
}

.error-color {
  color: $error;
}

.error-wrap {
  position: relative;
}