.input-text {
  position: relative;
  box-sizing: border-box;
  background-color: $input-bg-white;
  width: 100%;
  max-width: 100%;
  margin: 0;
  outline: none;
  font-family: inherit;
  font-size: 16px;
  line-height: 1;
  color: $input-text-gray;
  border: 1px solid $input-border;
  border-radius: 16px;
  transition: background-color 0.2s ease 0s,
  border-color 0.2s ease 0s;

  @include sm-md {
    height: 40px;
    padding: 6px 15px;
  }

  @include from-lg {
    height: 50px;
    padding: 19px 22px;
    font-size: 18px;
  }

  &:focus {
    border-color: $basic-black;
  }

  &.valid:valid {
    border-color: $basic-black;
  }


  &::placeholder {
    color: $basic-gray;

  }

  &--size {
    max-width: 357px;
  }

  &--disabled,
  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
}
