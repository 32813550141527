.industry {
  padding: 15% 0 10%;
  @include sm-md {
    padding: 100px 0 60px;
  }

  &__title {
    margin-bottom: 27px;
    @include from-lg {
      margin-bottom: 47px;
    }
  }

  &__round {
    width: 100px;
    height: 100px;
    background-image: $gradient-round;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  &__name {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 1;
    margin: 13px 0 0 0;
    text-align: center;
    @include sm-lg {
      font-size: 20px;
    }
    @include xlg-only {
      font-size: 22px;
    }
  }

  &__elem {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    opacity: 0;

    &:not(:last-child)::after {
      content: "";
      position: absolute;
      @include sm-md {
        bottom: -33px;
        width: 15px;
        height: 15px;
        background-image: url('../img/arrow.png');
      }

      @include from-lg {
        right: -33px;
        top: 50px;
        width: 30px;
        height: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url('../img/arrow-long.png');
        background-size: 30px 14px;
        background-repeat: no-repeat;
      }
    }
  }

  &__block {
    display: flex;
    grid-gap: 56px;
    @include sm-md {
      flex-direction: column;
    }
    @include from-lg {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 30px;
    }
  }
}
