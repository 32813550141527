.header {
  position: absolute;
  top: 0;
  z-index: 11;
  padding: 28px 20px;
  width: calc(100% - 40px);

  @include sm-md {
    padding: 20px 0;
    width: 100%;
  }


  &.active {
    z-index: 26;
  }

  &__logo-svg {
    @include sm-md {
      width: 100%;
      fill: #000000;
    }
  }

  &--new {
    @include sm-md {
      padding-top: 34px;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
  }

  &__logo {
    position: relative;
    z-index: 20;
    width: 107px;
    height: 37px;
  }

  &__button-wrapper {
    display: flex;
    align-items: center;
  }

  &__button {
    box-sizing: border-box;
    border-radius: 40px;
    margin-right: 14px;
    @include sm-md {
      font-size: 14px;
      padding: 4px 14px;
    }
    @include sm-only {
      font-size: 12px;
    }
    @include lg-only {
      font-size: 14px;
      padding: 9px 14px;
    }

    @include xlg-only {
      padding: 9px 7px;
    }

    @include from-xxlg {
      padding: 15px 18px;
    }

    @include from-xxlg {
      font-size: 16px;
    }
  }

  &.fixed {
    position: fixed;
    top: 0;
    @include sm-lg {
      background-color: rgba(27, 139, 61, 1);
    }
    @include from-xlg {
      background-color: $basic-white;
      box-shadow: 30px 20px 50px rgba(0, 0, 0, 0.1);
    }

    .header__logo-svg {
      @include sm-lg {
        fill: #ffffff;
      }
    }
  }
}
